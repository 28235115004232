export const apiStatics = {
  blogs: {
    blogs: "blogs",
  },
  services: {
    services: "services",
    plans: "plans",
    addOns: "add-ons",
  },
  razorpay: {
    razorpay: "razorpay",
  },
  leads: {
    leads: "leads",
  },
};
