import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import officePlan from "../../Assets/Images/office-plan.svg";
import kt from "../../Assets/Icons/States/karnataka.svg";
import dl from "../../Assets/Icons/States/Delhi.svg";
import gj from "../../Assets/Icons/States/gj.svg";
import br from "../../Assets/Icons/States/bh.svg";
import tl from "../../Assets/Icons/States/telangana.svg";
import hr from "../../Assets/Icons/States/hr.svg";
import tn from "../../Assets/Icons/States/tn.svg";
import pb from "../../Assets/Icons/States/pb.svg";
import rj from "../../Assets/Icons/States/rj.svg";
import wb from "../../Assets/Icons/States/wb.svg";
import mh from "../../Assets/Icons/States/mh.svg";
import as from "../../Assets/Icons/States/as.svg";
import up from "../../Assets/Icons/States/up.svg";
import mp from "../../Assets/Icons/States/mp.svg";
import axios from "axios";
import { apiStatics } from "../../Utils/apiStatics";

export const createLead = createAsyncThunk(
  `plan/createLead`,
  async ({ values }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/${apiStatics.leads.leads}`,
        values
      );
      return {
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const getAllPlans = createAsyncThunk(`plan/getAllPlans`, async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/${apiStatics.services.services}`
    );
    return {
      data: response.data.data.data,
      total: response.data.total,
    };
  } catch (error) {
    console.log("error", error);
    return {
      success: false,
      error: error.message,
    };
  }
});
export const getPlanAddOns = createAsyncThunk(
  `plan/getPlanAddOns`,
  async ({ serviceId, stateId, planId }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/${apiStatics.services.services}/${serviceId}/${apiStatics.services.plans}/${planId}/${apiStatics.services.addOns}`,
        { stateId: stateId ? stateId : null }
      );
      console.log("response.data in addons", response.data);
      return {
        data: response.data.data,
      };
    } catch (error) {
      console.log("error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const createRazorpayOrder = createAsyncThunk(
  `plan/createRazorpayOrder`,
  async ({ data, dispatch, loadScript, handleSuccessRazorpayResponse }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/${apiStatics.razorpay.razorpay}`,
        data
      );
      console.log("response.data in razorpay", response.data.data.data);
      loadScript("https://checkout.razorpay.com/v1/checkout.js").then((res) => {
        if (!res) {
          console.log("Razorpay SDK failed to load. Are you online?");
          return;
        }

        const options = {
          key: response.data.data.data.rzpKey,
          amount: response.data.data.data.amount,
          name: `Service Booking`,
          order_id: response.data.data.data.id,
          prefill: {
            name: data.name,
            contact: data.phone,
            email: data.email,
          },
          handler: handleSuccessRazorpayResponse,
          modal: {
            confirm_close: true,
          },
          theme: { color: "#CC202E" },
          // send_sms_hash: true,
        };

        console.log(options, "options");

        const rzp1 = new window.Razorpay(options);

        // To get payment id in case of failed transaction.

        rzp1.on("payment.failed", (response) => {
          dispatch(openPaymentFailedDialog());
        });

        rzp1.open();
      });
      return {
        data: response.data.data,
      };
    } catch (error) {
      console.log("error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
const initialState = {
  allServices: [],
  selectedServiceNew: {},
  selectedPlanNew: {},
  selectedStateNew: {},
  paymentSuccessDialog: false,
  leadGenerated: false,
  paymentFailedDialog: false,
  disabledContinue: false,
  planAddOns: [],
  updateLoading: false,
  services: [
    {
      title: "Office",
      image: officePlan,
      showState: true,
      plans: [
        {
          title: "Virtual Office",
          type: "yearly",
          states: [
            {
              name: "Karnataka",
              icon: kt,
              price: 12000,
            },
            {
              name: "Haryana",
              icon: hr,
              price: 12000,
            },
            {
              name: "Telangana",
              icon: tl,
              price: 12000,
            },
            {
              name: "Maharashtra",
              icon: mh,
              price: 15000,
            },
            {
              name: "Gujarat",
              icon: gj,
              price: 20000,
            },
            {
              name: "West Bengal",
              icon: wb,
              price: 15000,
            },
            {
              name: "Assam",
              icon: as,
              price: 20000,
            },
            {
              name: "Tamil Nadu",
              icon: tn,
              price: 20000,
            },
            {
              name: "Punjab",
              icon: pb,
              price: 15000,
            },
            {
              name: "Rajasthan",
              icon: rj,
              price: 15000,
            },
            {
              name: "Bihar",
              icon: br,
              price: 20000,
            },
            {
              name: "Delhi",
              icon: dl,
              price: 12000,
            },
            {
              name: "Uttar Pradesh",
              icon: up,
              price: 15000,
            },
            {
              name: "Madhya Pradesh",
              icon: mp,
              price: 15000,
            },
          ],

          addOns: [
            {
              title: "Recommended Add Ons",
              features: [
                {
                  title: "Authorized Representative",
                  price: 6000,
                },
                {
                  title: "Notarisation",
                  price: 1500,
                },
                {
                  title: "Mailing Service",
                  price: 2000,
                },
              ],
            },
            {
              title: "Recommended Additional Registration (individual service)",
              features: [
                {
                  title: "Shop and Establishment",
                  price: 0,
                },
              ],
            },
          ],
        },
        {
          title: "Shared Desk",
          type: "yearly",
          states: [
            {
              name: "Karnataka",
              icon: kt,
              price: 22000,
            },
            {
              name: "Haryana",
              icon: hr,
              price: 22000,
            },
            {
              name: "Telangana",
              icon: tl,
              price: 22000,
            },
            {
              name: "Maharashtra",
              icon: mh,
              price: 30000,
            },
            {
              name: "Gujarat",
              icon: gj,
              price: 30000,
            },
            {
              name: "West Bengal",
              icon: wb,
              price: 28000,
            },
            {
              name: "Assam",
              icon: as,
              price: 30000,
            },
            {
              name: "Tamil Nadu",
              icon: tn,
              price: 30000,
            },
            {
              name: "Punjab",
              icon: pb,
              price: 25000,
            },
            {
              name: "Rajasthan",
              icon: rj,
              price: 25000,
            },
            {
              name: "Bihar",
              icon: br,
              price: 30000,
            },
            {
              name: "Delhi",
              icon: dl,
              price: 25000,
            },
            {
              name: "Uttar Pradesh",
              icon: up,
              price: 25000,
            },
            {
              name: "Madhya Pradesh",
              icon: mp,
              price: 30000,
            },
          ],

          addOns: [
            {
              title: "Recommended Add Ons",
              features: [
                {
                  title: "Authorized Representative",
                  price: 6000,
                },
                {
                  title: "Notarisation",
                  price: 1500,
                },
              ],
            },
            {
              title: "Recommended Additional Registration (individual service)",
              features: [
                {
                  title: "Shop and Establishment",
                  price: 0,
                },
              ],
            },
          ],
        },
        {
          title: "Dedicated Desk",
          type: "yearly",
          states: [
            {
              name: "Karnataka",
              icon: kt,
              price: 40000,
            },
            {
              name: "Haryana",
              icon: hr,
              price: 40000,
            },
            {
              name: "Telangana",
              icon: tl,
              price: 40000,
            },
            {
              name: "Maharashtra",
              icon: mh,
              price: 55000,
            },
            {
              name: "Gujarat",
              icon: gj,
              price: 55000,
            },
            {
              name: "West Bengal",
              icon: wb,
              price: 55000,
            },
            {
              name: "Assam",
              icon: as,
              price: 60000,
            },
            {
              name: "Tamil Nadu",
              icon: tn,
              price: 60000,
            },
            {
              name: "Punjab",
              icon: pb,
              price: 50000,
            },
            {
              name: "Rajasthan",
              icon: rj,
              price: 50000,
            },
            {
              name: "Bihar",
              icon: br,
              price: 60000,
            },
            {
              name: "Delhi",
              icon: dl,
              price: 45000,
            },
            {
              name: "Uttar Pradesh",
              icon: up,
              price: 50000,
            },
            {
              name: "Madhya Pradesh",
              icon: mp,
              price: 60000,
            },
          ],

          addOns: [
            {
              title: "Recommended Add Ons",
              features: [
                {
                  title: "Authorized Representative",
                  price: 6000,
                },
                {
                  title: "Notarisation",
                  price: 1500,
                },
              ],
            },
            {
              title: "Recommended Additional Registration (individual service)",
              features: [
                {
                  title: "Shop and Establishment",
                  price: 0,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  selectedState: { name: "Karnataka", price: 12000 },
  selectedTenure: { title: "", price: 0 },
  selectedService: {
    name: "Virtual Office",
    price: 12000,
    image: officePlan,
    showState: true,
    plans: [
      {
        title: "Virtual Office",
        type: "yearly",
        states: [
          {
            name: "Karnataka",
            icon: kt,
            price: 12000,
          },
          {
            name: "Haryana",
            icon: hr,
            price: 12000,
          },
          {
            name: "Telangana",
            icon: tl,
            price: 12000,
          },
          {
            name: "Maharashtra",
            icon: mh,
            price: 15000,
          },
          {
            name: "Gujarat",
            icon: gj,
            price: 20000,
          },
          {
            name: "West Bengal",
            icon: wb,
            price: 15000,
          },
          {
            name: "Assam",
            icon: as,
            price: 20000,
          },
          {
            name: "Tamil Nadu",
            icon: tn,
            price: 20000,
          },
          {
            name: "Punjab",
            icon: pb,
            price: 15000,
          },
          {
            name: "Rajasthan",
            icon: rj,
            price: 15000,
          },
          {
            name: "Bihar",
            icon: br,
            price: 20000,
          },
          {
            name: "Delhi",
            icon: dl,
            price: 12000,
          },
          {
            name: "Uttar Pradesh",
            icon: up,
            price: 15000,
          },
          {
            name: "Madhya Pradesh",
            icon: mp,
            price: 15000,
          },
        ],

        addOns: [
          {
            title: "Recommended Add Ons",
            features: [
              {
                title: "Authorized Representative",
                price: 6000,
              },
              {
                title: "Notarisation",
                price: 1500,
              },
            ],
          },
          {
            title: "Recommended Additional Registration (individual service)",
            features: [
              {
                title: "Shop and Establishment",
                price: 0,
              },
            ],
          },
        ],
      },
      {
        title: "Shared Desk",
        type: "yearly",
        states: [
          {
            name: "Karnataka",
            icon: kt,
            price: 22000,
          },
          {
            name: "Haryana",
            icon: hr,
            price: 22000,
          },
          {
            name: "Telangana",
            icon: tl,
            price: 22000,
          },
          {
            name: "Maharashtra",
            icon: mh,
            price: 30000,
          },
          {
            name: "Gujarat",
            icon: gj,
            price: 30000,
          },
          {
            name: "West Bengal",
            icon: wb,
            price: 28000,
          },
          {
            name: "Assam",
            icon: as,
            price: 30000,
          },
          {
            name: "Tamil Nadu",
            icon: tn,
            price: 30000,
          },
          {
            name: "Punjab",
            icon: pb,
            price: 25000,
          },
          {
            name: "Rajasthan",
            icon: rj,
            price: 25000,
          },
          {
            name: "Bihar",
            icon: br,
            price: 30000,
          },
          {
            name: "Delhi",
            icon: dl,
            price: 25000,
          },
          {
            name: "Uttar Pradesh",
            icon: up,
            price: 25000,
          },
          {
            name: "Madhya Pradesh",
            icon: mp,
            price: 30000,
          },
        ],

        addOns: [
          {
            title: "Recommended Add Ons",
            features: [
              {
                title: "Authorized Representative",
                price: 6000,
              },
              {
                title: "Notarisation",
                price: 1500,
              },
            ],
          },
          {
            title: "Recommended Additional Registration (individual service)",
            features: [
              {
                title: "Shop and Establishment",
                price: 0,
              },
            ],
          },
        ],
      },
      {
        title: "Dedicated Desk",
        type: "yearly",
        states: [
          {
            name: "Karnataka",
            icon: kt,
            price: 40000,
          },
          {
            name: "Haryana",
            icon: hr,
            price: 40000,
          },
          {
            name: "Telangana",
            icon: tl,
            price: 40000,
          },
          {
            name: "Maharashtra",
            icon: mh,
            price: 55000,
          },
          {
            name: "Gujarat",
            icon: gj,
            price: 55000,
          },
          {
            name: "West Bengal",
            icon: wb,
            price: 55000,
          },
          {
            name: "Assam",
            icon: as,
            price: 60000,
          },
          {
            name: "Tamil Nadu",
            icon: tn,
            price: 60000,
          },
          {
            name: "Punjab",
            icon: pb,
            price: 50000,
          },
          {
            name: "Rajasthan",
            icon: rj,
            price: 50000,
          },
          {
            name: "Bihar",
            icon: br,
            price: 60000,
          },
          {
            name: "Delhi",
            icon: dl,
            price: 45000,
          },
          {
            name: "Uttar Pradesh",
            icon: up,
            price: 50000,
          },
          {
            name: "Madhya Pradesh",
            icon: mp,
            price: 60000,
          },
        ],

        addOns: [
          {
            title: "Recommended Add Ons",
            features: [
              {
                title: "Authorized Representative",
                price: 6000,
              },
              {
                title: "Notarisation",
                price: 1500,
              },
            ],
          },
          {
            title: "Recommended Additional Registration (individual service)",
            features: [
              {
                title: "Shop and Establishment",
                price: 0,
              },
            ],
          },
        ],
      },
    ],
    additionalStep: {
      //nullable
      title: "STEP 2",
      description: "Tenure",
      subtitle: "Choose your Tenure",
      tenures: [
        {
          title: "1 Year Plan",
          discountedPrice: 12000,
          actualPrice: 14200,
          savings: null,
        },
        {
          title: "2 Years Plan",
          discountedPrice: 21600,
          actualPrice: 25200,
          savings: {
            price: 3600,
            extras: "( 10% Discount + 10% Yearly Rent Hike )",
          },
        },
        {
          title: "3 Years Plan",
          discountedPrice: 42600,
          actualPrice: 46200,
          savings: {
            price: 3600,
            extras: "( 10% Discount + 10% Yearly Rent Hike )",
          },
        },
      ],
    },
  },
  selectedPlan: {
    title: "Virtual Office",
    type: "yearly",
    states: [
      {
        name: "Karnataka",
        icon: kt,
        price: 12000,
      },
      {
        name: "Haryana",
        icon: hr,
        price: 12000,
      },
      {
        name: "Telangana",
        icon: tl,
        price: 12000,
      },
      {
        name: "Maharashtra",
        icon: mh,
        price: 15000,
      },
      {
        name: "Gujarat",
        icon: gj,
        price: 20000,
      },
      {
        name: "West Bengal",
        icon: wb,
        price: 15000,
      },
      {
        name: "Assam",
        icon: as,
        price: 20000,
      },
      {
        name: "Tamil Nadu",
        icon: tn,
        price: 20000,
      },
      {
        name: "Punjab",
        icon: pb,
        price: 15000,
      },
      {
        name: "Rajasthan",
        icon: rj,
        price: 15000,
      },
      {
        name: "Bihar",
        icon: br,
        price: 20000,
      },
      {
        name: "Delhi",
        icon: dl,
        price: 12000,
      },
      {
        name: "Uttar Pradesh",
        icon: up,
        price: 15000,
      },
      {
        name: "Madhya Pradesh",
        icon: mp,
        price: 15000,
      },
    ],

    addOns: [
      {
        title: "Recommended Add Ons",
        features: [
          {
            title: "Authorized Representative",
            price: 6000,
          },
          {
            title: "Notarisation",
            price: 1500,
          },
        ],
      },
      {
        title: "Recommended Additional Registration (individual service)",
        features: [
          {
            title: "Shop and Establishment",
            price: 0,
          },
        ],
      },
    ],
  },
  // selectedSer
  selectedStep: 0,

  selectedAddOns: [
    {
      name: "",
      price: 0,
    },
  ],
  summary: {
    planPrice: 12000,
    tenure: "",
    total: 0,
    addons: [],
    addonAdditionalItemIds: [],
    addOnStateIds: [],
  },
};

const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    // Define your reducer functions here
    setSelectedStep: (state, action) => {
      state.selectedStep = action.payload;
      state.selectedTenure = { title: "", price: 0 };
      state.selectedAddOns = [
        {
          name: "",
          price: 0,
        },
      ];
    },
    toggleContinue: (state, action) => {
      state.disabledContinue = action.payload.state;
    },
    resetSteps: (state, action) => {
      state.selectedStep = 0;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    openPaymentSuccessDialog: (state, action) => {
      state.paymentSuccessDialog = true;
    },
    closePaymentSuccessDialog: (state, action) => {
      state.paymentSuccessDialog = false;
    },
    closeLeadGeneratedDialog: (state, action) => {
      state.leadGenerated = false;
    },
    openPaymentFailedDialog: (state, action) => {
      state.paymentFailedDialog = true;
    },
    closePaymentFailedDialog: (state, action) => {
      state.paymentFailedDialog = false;
    },
    setSelectedPlanNew: (state, action) => {
      state.selectedPlanNew = action.payload;
      // state.selectedStateNew = {};
      state.summary = {
        planPrice: 0,
        tenure: "",
        total: 0,
        addons: [],
        addonAdditionalItemIds: [],
        addOnStateIds: [],
      };
    },
    setSelectedServiceNew: (state, action) => {
      state.selectedServiceNew = action.payload;
      state.selectedPlanNew = action.payload.plans[0];
      state.summary = {
        planPrice: 0,
        tenure: "",
        total: 0,
        addons: [],
        addonAdditionalItemIds: [],
        addOnStateIds: [],
      };
      state.selectedStateNew = action.payload.plans[0].states
        ? action.payload.plans[0].states[0]
        : {};

      // new code
      state.loading = false;
      state.selectedService.price = action.payload.plans[0].states
        ? action.payload.plans[0].states[0].price
        : action.payload.plans[0].price;
      state.summary.planPrice = action.payload.plans[0].states
        ? action.payload.plans[0].states[0].price
        : action.payload.plans[0].price;
      // state.total = action.payload.total;
    },

    setSelectedTenure: (state, action) => {
      console.log("action", action);
      state.selectedTenure = action.payload.tenure;
      state.summary.tenure = action.payload.tenure.name;
      // const tmp = state.summary.planPrice;
      // const newPrice =
      //   action.payload.discountedPriceMultiplier * state.summary.planPrice;
      state.summary.planPrice = action.payload.price;
    },
    setSummaryPlanPrice: (state, action) => {
      state.summary.planPrice = action.payload;
      state.summary.total += Number(action.payload);
    },
    setSelectedState: (state, action) => {
      state.selectedState = action.payload;
      state.selectedService.price = action.payload.price;
    },
    setSelectedStateNew: (state, action) => {
      state.selectedStateNew = action.payload;
      // state.selectedService.price = action.payload.price;
    },
    setServiceAddonBySelect: (state, action) => {
      const allAddOns = [...current(state.planAddOns)];
      const addOnsInSummary = [...current(state.summary.addons)];
      const addOnExternalId = action.payload.id;
      const addOnDropDownId = action.payload.t.value;
      const desiredAddOnIndexInState = allAddOns.findIndex(
        (data) => data.id === addOnExternalId
      );

      const selectedAddOnAdditionalItemsInState = allAddOns[
        desiredAddOnIndexInState
      ].additionalItems
        ? allAddOns[desiredAddOnIndexInState].additionalItems
        : allAddOns[desiredAddOnIndexInState].states;
      const requiredAdditionalItemIndexInState =
        selectedAddOnAdditionalItemsInState.findIndex(
          (data) => data.id === addOnDropDownId
        );
      const requiredAddOnInSmmaryIndex = addOnsInSummary.findIndex(
        (data) => data.id === addOnExternalId
      );
      state.summary.addons[requiredAddOnInSmmaryIndex].additionalId =
        action.payload.t.value;
      if (
        selectedAddOnAdditionalItemsInState[requiredAdditionalItemIndexInState]
          .discountedPriceMultiplier
      ) {
        addOnsInSummary[requiredAddOnInSmmaryIndex] = {
          ...addOnsInSummary[requiredAddOnInSmmaryIndex],
          name: action.payload.t.extra + " - " + action.payload.t.label,
          price:
            action.payload.price *
            selectedAddOnAdditionalItemsInState[
              requiredAdditionalItemIndexInState
            ].discountedPriceMultiplier,
          additionalItemId: action.payload.t.value,
        };
      } else if (
        selectedAddOnAdditionalItemsInState[requiredAdditionalItemIndexInState]
          .discountedPrice
      ) {
        addOnsInSummary[requiredAddOnInSmmaryIndex] = {
          ...addOnsInSummary[requiredAddOnInSmmaryIndex],
          name: action.payload.t.extra + " - " + action.payload.t.label,
          price:
            selectedAddOnAdditionalItemsInState[
              requiredAdditionalItemIndexInState
            ].discountedPrice,
          additionalItemId: action.payload.t.value,
        };
      } else if (
        selectedAddOnAdditionalItemsInState[requiredAdditionalItemIndexInState]
          .price
      ) {
        // const tempAdditional = [...current(state.summary.addOnStateIds)];
        // if (!tempAdditional.includes(action.payload.t.value)) {
        //   tempAdditional.push(action.payload.t.value);
        // }
        // state.summary.addOnStateIds = tempAdditional;
        addOnsInSummary[requiredAddOnInSmmaryIndex] = {
          ...addOnsInSummary[requiredAddOnInSmmaryIndex],
          name: action.payload.t.extra + " - " + action.payload.t.label,
          price:
            selectedAddOnAdditionalItemsInState[
              requiredAdditionalItemIndexInState
            ].price,
          additionalStateId: action.payload.t.value,
        };
      }
      state.summary.addons = addOnsInSummary;
    },
    resetAddOns: (state, action) => {
      state.summary.addons = [];
    },
    setServiceAddons: (state, action) => {
      const temp = [...state.summary.addons];
      if (action.payload.e.target.checked) {
        temp.push({
          price: action.payload.price,
          name: action.payload.e.target.name,
          id: action.payload.id,
          stateId: action.payload.stateId,
          haveMore: action.payload.haveMore,
        });
        state.summary.addons = temp;
      } else {
        state.summary.addons = temp.filter(
          (obj) => obj.id !== action.payload.id
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.allServices = action.payload.data;
        state.selectedServiceNew = action.payload.data[0];
        state.selectedPlanNew = action.payload.data[0].plans[0];

        state.selectedStateNew = action.payload.data[0].plans[0].states[0];
        state.selectedService.price =
          action.payload.data[0].plans[0].states[0].price;
        state.summary.planPrice =
          action.payload.data[0].plans[0].states[0].price;
        state.total = action.payload.total;
      })
      .addCase(getAllPlans.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getPlanAddOns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlanAddOns.fulfilled, (state, action) => {
        state.loading = false;
        state.planAddOns = action.payload.data;
      })
      .addCase(getPlanAddOns.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(createLead.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(createLead.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.leadGenerated = true;
      })
      .addCase(createLead.rejected, (state) => {
        state.updateLoading = false;
      });
    builder
      .addCase(createRazorpayOrder.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(createRazorpayOrder.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(createRazorpayOrder.rejected, (state) => {
        state.updateLoading = false;
      });
  },
});

export const {
  /* Add your action creators here */
  setSelectedPlan,
  setSelectedState,
  setSummaryPlanPrice,
  setSelectedStep,
  setSelectedTenure,
  setServiceAddons,
  setSelectedServiceNew,
  setSelectedPlanNew,
  setSelectedStateNew,
  closePaymentSuccessDialog,
  closeLeadGeneratedDialog,
  openPaymentSuccessDialog,
  openPaymentFailedDialog,
  closePaymentFailedDialog,
  resetSteps,
  setServiceAddonBySelect,
  toggleContinue,
  resetAddOns,
} = planSlice.actions;
export default planSlice.reducer;
